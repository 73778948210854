<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import ApiService from "@/services/jobsiteTrackingService";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      config: {},
      scanMode0: false,
    };
  },
  methods: {
    loadData() {
      this.isLoading = true;
      ApiService.getOrganisationConfig().then((result) => {
        this.config = result.data.data;
        this.isLoading = false;
      });
    },
    save() {
      this.isLoading = true;
      ApiService.saveOrganisationConfig(this.config).then(() => {
        this.isLoading = false;
        this.$store.dispatch("userconfig/clearUserConfig");
      });
    },
    discard() {
      this.loadData();
    },
    toggleAllStepNotification(){
      for(var step of this.config.stepConfigurationResources){
        step.hasNotification = !step.hasNotification
      }
    },
    toggleAllStepSignature() {
      for(var step of this.config.stepConfigurationResources){
        step.signatureRequired = !step.signatureRequired
      }
    }
  },
  created() {
    this.loadData();
  },
  watch: {
    config: function (value) {
      if (value.typeScan == 1) {
        this.scanMode0 = false;
      } else {
        this.scanMode0 = true;
      }
    },
    scanMode0: function (value) {
      if (value) {
        this.config.typeScan = 0;
      } else {
        this.config.typeScan = 1;
      }
    },
  },
};
</script>
<template>
  <Layout>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    >
    </loading>
    <div
      style="
        flex: 1 1 1px;
        display: flex;
        flex-direction: column;
        margin: 0 -24px -60px -24px;
      "
    >
      <div
        class="p-2 pl-4 pr-4 pb-2"
        style="padding-right: 2.5rem !important; padding-bottom: 0 !important"
      >
        <div
          style="
            padding: 20px;
            background: white;
            border-bottom: 1px solid rgba(204, 204, 204, 0.8);
          "
        >
          <b-button @click="save" variant="success" class="mr-2">{{
            $t("general.save")
          }}</b-button>
          <b-button @click="discard" variant="light">{{
            $t("general.discard")
          }}</b-button>
        </div>
      </div>
      <div
        class="p-2 pl-4 pr-4"
        style="
          flex: 1 1 1px;
          min-height: 0;
          overflow-y: scroll;
          position: relative;
        "
      >
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center col-auto">
              <h5 class="p-1 m-2 align-middle">
                {{ $t("general.scanmodemessage") }}
              </h5>
              <b-form-checkbox
                switch
                size="lg"
                v-model="scanMode0"
                class=""
              ></b-form-checkbox>
            </div>
            <hr />
            <div class="table-responsive">
                  <table class="table mb-0 table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>{{$t('general.status')}}</th>
                        <th style="width:250px; text-align:center;"><a @click="toggleAllStepNotification()" class="bx bx-toggle-left toggleHeaderBtn"></a>{{$t('general.notification')}}</th>
                        <th style="width:250px; text-align:center;"><a @click="toggleAllStepSignature()" class="bx bx-toggle-left toggleHeaderBtn"></a>{{$t('general.clientsignature')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(step, index) in config.stepConfigurationResources" :key="index">
                        <td>{{step.stepName}}</td>
                        <td style="text-align:center;">
                          <b-form-checkbox size="lg" switch v-model="step.hasNotification"></b-form-checkbox>
                        </td>
                        <td style="text-align:center;">
                          <b-form-checkbox size="lg" switch v-model="step.signatureRequired"></b-form-checkbox>
                        </td>
                      </tr>                                                         
                    </tbody>
                  </table>
                </div>    
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>